import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import Fade from 'react-reveal/Fade';
import CheckboxField from '../inputField/checkbox-field';
import RadioGroupField from '../inputField/radio-group-field';
// import axios from 'axios';
// import MultiSelection from '../inputField/multi-selection-field';

// import MultipleSelectField from '../inputField/multi-selection-field';
// import RegistrationJson from '../../../data/registration.json';
// import AOS from 'aos';
// import '../../../styles/sass/aos.css';
const options = new Array(4).fill(0).map((i, idx) => ({ value: `${idx + 1}`, label: `${idx + 1}` }));
export default function CheckBoxInputForm(props) {
  // useEffect(() => {
  //   AOS.init();
  // }, []);
  const {
    formField: { production, coordination, regulation, bmAndLTStrategy, recieveMoreInfo, conditions },
  } = props;
  // const [upComingEvents, setEvents] = useState();
  // const [isDataLoaded, setIsData] = useState(false);
  // useEffect(() => {
  //   axios
  //     .get(`${process.env.GATSBY_EMS_API_URL}events/upCommingEvents`)
  //     .then((response) => {
  //       if (response.status === 200) {
  //         setEvents(response.data);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }, []);

  // useEffect(() => {
  //   if (upComingEvents) {
  //     console.log('upcomingevents', upComingEvents);
  //     setIsData(true);
  //   }
  // }, [upComingEvents]);

  return (
    <Fade bottom>
      <Grid container>
        <Grid item xs={12}>
          <Typography gutterBottom color='textSecondary' variant='h5' style={{ maxWidth: '787px' }}>
            Kindly rank the relevance of each of the four working groups according to your preference, with 1 being the
            most valuable and 4 being the least valuable.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <table
            style={{
              border: '1px solid #a4c627',
              width: '100%',
              borderCollapse: 'collapse',
              textAlign: 'center',
              marginBottom: 20,
              marginTop: 20,
              borderRadius: 12,
            }}>
            <tr>
              <th
                style={{
                  borderLeft: '1px solid #a4c627',
                  borderTop: '1px solid #a4c627',
                  verticalAlign: 'center',
                  padding: 8,
                }}>
                <Typography color='primary' variant='h4' align='center'>
                  Working Groups
                </Typography>
              </th>
              <th style={{ borderLeft: '1px solid #a4c627', borderTop: '1px solid #a4c627', verticalAlign: 'middle', }}>
                <Typography color='primary' variant='h4' align='center'>
                  1
                </Typography>
              </th>
              <th style={{ borderLeft: '1px solid #a4c627', borderTop: '1px solid #a4c627', verticalAlign: 'middle' }}>
                <Typography color='primary' variant='h4' align='center'>
                  2
                </Typography>
              </th>
              <th style={{ borderLeft: '1px solid #a4c627', borderTop: '1px solid #a4c627', verticalAlign: 'middle' }}>
                <Typography color='primary' variant='h4' align='center'>
                  3
                </Typography>
              </th>
              <th style={{ borderLeft: '1px solid #a4c627', borderTop: '1px solid #a4c627', verticalAlign: 'middle' }}>
                <Typography color='primary' variant='h4' align='center'>
                  4
                </Typography>
              </th>
            </tr>

            <RadioGroupField name={coordination.name} label={coordination.label} options={options} />
            <RadioGroupField name={regulation.name} label={regulation.label} options={options} />
            <RadioGroupField name={production.name} label={production.label} options={options} />
            <RadioGroupField name={bmAndLTStrategy.name} label={bmAndLTStrategy.label} options={options} />
          </table>
        </Grid>

        <Grid item xs={12}>
          <Typography gutterBottom color='textSecondary'>
            Please select one or more from the below options:
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {/* <MultipleSelectField name={events.name} label={events.label} data={RegistrationJson.events} fullWidth /> */}
          {/* <CheckboxField name={events.name} label={events.label} /> */}
          {/* <CheckboxField name={accreditedMedia.name} label={accreditedMedia.label} /> */}
          {/* {isDataLoaded === true ? (
            <MultiSelection name={events.name} label={events.label} data={upComingEvents} />
          ) : null} */}

          <CheckboxField name={recieveMoreInfo.name} label={recieveMoreInfo.label} />
          <CheckboxField name={conditions.name} label={conditions.label} terms={true} />
        </Grid>
      </Grid>
    </Fade>
  );
}
