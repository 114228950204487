import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import InputField from '../inputField/Input-field';
import SelectField from '../inputField/select-field';
import RegistrationJson from '../../../data/registration.json';
import SearchSelectField from '../inputField/search-select-field';
import Fade from 'react-reveal/Fade';
// import AOS from 'aos';
// import '../../../styles/sass/aos.css';

export default function PersonalDetailsForm(props) {
  // useEffect(() => {
  //   AOS.init();
  // }, []);
  const [jobRolSpecify, setJobRoleSpecify] = useState(false);
  const [industrySectorSpecifyValue, setIndustrySectorSpecify] = useState(false);

  const specifyJobRoleCallback = (bool) => {
    setJobRoleSpecify(bool);
  };

  const specifyIndustrySectorCallback = (bool) => {
    setIndustrySectorSpecify(bool);
  };
  const {
    formField: { jobTitle, jobRole, jobRoleSpecify, company, industrySector, industrySectorSpecify },
  } = props;
  return (
    <Fade bottom>
      <Grid container>
        <Grid container>
          <Grid item xs={12}>
            <InputField limitchar={41} label={jobTitle.label} name={jobTitle.name} fullWidth />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <SelectField
            name={jobRole.name}
            label={jobRole.label}
            data={RegistrationJson.jobRoleStates}
            specifyJobRoleCallback={specifyJobRoleCallback}
            fullWidth
          />
        </Grid>

        {jobRolSpecify === true && (
          <Grid item xs={12}>
            <InputField name={jobRoleSpecify.name} label={jobRoleSpecify.label} fullWidth />
          </Grid>
        )}

        <Grid item xs={12}>
          <InputField label={company.label} name={company.name} />
        </Grid>

        <Grid item xs={12}>
          <SearchSelectField
            name={industrySector.name}
            label={industrySector.label}
            data={RegistrationJson.industrySectorStates}
            specifyIndustrySectorCallback={specifyIndustrySectorCallback}
          />
        </Grid>

        {industrySectorSpecifyValue === true && (
          <Grid item xs={12}>
            <InputField name={industrySectorSpecify.name} label={industrySectorSpecify.label} fullWidth />
          </Grid>
        )}
      </Grid>
    </Fade>
  );
}
