import React from 'react';

import { FormHelperText, Radio, Typography } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';

const RadioGroupField = ({ name, options, label, ...props }) => {
  const [field, meta] = useField({ name });

  const { setFieldValue } = useFormikContext();

  const handleChange = (event) => {
    setFieldValue(name, event.target.value);
  };
  return (
    <tr>
      <td
        style={{
          borderLeft: '1px solid #a4c627',
          borderTop: '1px solid #a4c627',
          verticalAlign: 'middle',
          padding: 8,
        }}>
        <Typography gutterBottom color='primary' variant='h5'>
          {label}
        </Typography>
        <FormHelperText>{meta.touched && meta.error ? meta.error : ''}</FormHelperText>
      </td>
      {options.map((option) => {
        return (
          <td
            key={option.value}
            style={{
              borderLeft: '1px solid #a4c627',
              borderTop: '1px solid #a4c627',
              verticalAlign: 'middle',
              width: 150,
            }}>
            <Radio checked={field.value === option.value} value={option.value} name={name} onChange={handleChange} />
          </td>
        );
      })}
    </tr>
  );
};

export default RadioGroupField;
