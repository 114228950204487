import { Box, Grid } from '@material-ui/core';
import React from 'react';
import RegistrationJson from '../../../data/registration.json';
import InputField from '../inputField/Input-field';
import SearchSelectField from '../inputField/search-select-field';
import SelectField from '../inputField/select-field';
// import AOS from 'aos';
// import '../../../styles/sass/aos.css';
import Fade from 'react-reveal/Fade';
export default function PersonalDetailsForm(props) {
  // useEffect(() => {
  //   AOS.init();
  // }, []);
  const {
    formField: { salutation, firstName, lastName, email, confirmedEmail, country, nationality, countryCode, mobile },
    allValues: allValues,
  } = props;
  return (
    <Fade bottom>
      <Grid container justify='space-between'>
        <Grid item xs={12}>
          <SelectField name={salutation.name} label={salutation.label} data={RegistrationJson.salutation} fullWidth />
        </Grid>

        <Grid item xs={12}>
          <InputField label={firstName.label} name={firstName.name} />
        </Grid>

        <Grid item xs={12}>
          <InputField label={lastName.label} name={lastName.name} />
        </Grid>

        <Grid item xs={12}>
          <InputField label={email.label} name={email.name} />
        </Grid>

        <Grid item xs={12}>
          <InputField label={confirmedEmail.label} name={confirmedEmail.name} />
        </Grid>

        <Grid item xs={12}>
          <SearchSelectField name={country.name} label={country.label} data={RegistrationJson.country} />
          {/* <SelectField name={country.name} label={country.label} data={RegistrationJson.country} fullWidth /> */}
        </Grid>
        <Grid item xs={12}>
          {/* <InputField label={nationality.label} name={nationality.name} /> */}
          <SearchSelectField name={nationality.name} label={nationality.label} data={RegistrationJson.country} />
        </Grid>

        <Grid item xs={2} lg={1}>
          <InputField
            value={allValues.country && allValues.country.dialCode ? allValues.country.dialCode : ''}
            disabled
            label={countryCode.label}
            name={countryCode.name}
          />
        </Grid>
        <Box clone pl={2}>
          <Grid item xs={10} lg={11}>
            <InputField label={mobile.label} name={mobile.name} />
          </Grid>
        </Box>
      </Grid>
    </Fade>
  );
}
