import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import GroupRegistrationFrom from '../../../components/group-registration-form/registration-from';
import Layout from '../../../components/layout';
import SEO from '../../../components/seo';
import withLocation from '../../../components/withLocation';
import homeBanner from './home-banner.webp';

import { Grid, Typography } from '@material-ui/core';
const Register = ({ search, navigate }) => {
  const { q } = search;
  const [source, setSource] = useState('Website');
  useEffect(() => {
    if (q && q.length > 0) {
      const _allowed = [
        'instagram',
        'linkedin',
        'facebook',
        'youtube',
        'gs',
        'gdn',
        'ns',
        'twitter',
        'tiktok',
        'sap',
        'etisalat',
        'tgc',
        'dewa',
        'adq',
        'fab',
        'etihad-rail',
        'ega',
        'ge',
        'schneider',
        'edb',
        'sberbank',
        'enec',
        'mubadala',
        'thales',
        'adnoc',
        'guest-invites',
        'speaker',
        'g42',
        'makingprosperity',
        'rta',
        'workinggroup',
        'microsoft',
        'ICTFUND',
        'EcGdn',
        'EcArabianBusiness',
        'EcTheNationalPg',
        'EcTheNational',
        'EcAlBayan',
        'EcEmaratAlYoum',
        'EcGulfNews',
        'EcAlWatan',
        'EcTwitter',
        'EcLinkedin',
        'EcGs',
        'EcUaeBarq',
        'EcForbes',
        'F6s',
        'insead',
        'sedd',
        'uae-gov',
        'whatsapp',
      ];
      if (_allowed.includes(q)) setSource(q);
      else navigate('/registration');
    }
  }, []);
  return (
    <Layout>
      <SEO
        lang='en'
        title='Expert Group Meeting (EGM) Registration | Global Manufacturing & Industrialisation Summit | GMIS 2024'
      />
      <img src={homeBanner} alt='image' style={{ width: '100%' }}></img>

      <Grid
        container
        style={{
          maxWidth: 1440,
          margin: 'auto',
        }}>
        <Grid item xs={12} spacing={12}>
          <Typography
            gutterBottom
            style={{
              margin: '30px 12px',
            }}
            color='primary'
            variant='h2'
            align='center'>
            Project Overview
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            style={{
              margin: '30px 20px',
              fontWeight: 'lighter',
            }}
            color='textSecondary'
            variant='h5'>
            The UNIDO International Centre for Health Industry Readiness is a joint initiative between the DoH, GMOL,
            and UNIDO established under a Memorandum of Understanding (MoU) in 2023. The Centre's mission is to support
            global health industry readiness, with a focus on pandemic preparedness, fostering innovation, and advancing
            health product manufacturing.
            <br />
            <br />
            The Centre is currently being aligned with the Abu Dhabi Healthcare Life Science Strategy 2040. This
            alignment aims to position Abu Dhabi as a global leader in life sciences, with a specific focus on advanced
            technologies, regulatory excellence, and sustainable industrial development. The Centre will serve as a hub
            for partnerships, supporting the growth of the health industry across the MENA region.
          </Typography>
        </Grid>
        <hr
          style={{
            margin: '40px 0',
            width: '100%',
            height: '0.5px',
            border: 'none',
            backgroundColor: '#333333',
          }}
        />

        <Grid item xs={12}>
          <Typography
            gutterBottom
            style={{
              margin: '30px 12px',
            }}
            color='primary'
            variant='h2'
            align='center'>
            About the Expert Group Meeting (EGM)
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            style={{
              margin: '30px 20px',
              fontWeight: 'lighter',
            }}
            color='textSecondary'
            variant='h5'>
            The EGM aims to define the UNIDO Centre’s operating model and long-term strategy in alignment with the Abu
            Dhabi Life Science Hub. This event will gather stakeholders and experts to explore how the Centre can
            contribute to the Hub’s development through Coordination, Regulation, and Production. <br />
            <br />
            Participants will join working group sessions to provide insights into the most suitable legal and
            operational frameworks for the Centre. The EGM will focus on identifying how the UNIDO Centre can:
            <ul>
              <li>Align with the Abu Dhabi Healthcare Life Science Strategy 2040</li>
              <li>Develop a sustainable operating model and financial strategy</li>
              <li>
                Support the growth of the Life Science Hub, focusing on partnerships, regulations, and production
                capabilities
              </li>
            </ul>
          </Typography>
        </Grid>
        <hr
          style={{
            margin: '40px 0',
            width: '100%',
            height: '0.5px',
            border: 'none',
            backgroundColor: '#333333',
          }}
        />

        <Grid item xs={12}>
          <Typography
            gutterBottom
            style={{
              margin: '30px 12px',
            }}
            color='primary'
            variant='h2'
            align='center'>
            Working Groups
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            style={{
              margin: '30px 20px',
              fontWeight: 100,
            }}
            color='textSecondary'
            variant='h5'>
            During the two-day workshop, participants will join one of four expert working groups, each focusing on a
            key area critical to the Centre’s success:
            <ol>
              <li>
                <b style={{ color: '#a4c627' }}>Coordination:</b> Enhancing collaboration and partnerships within the
                health industry ecosystem
              </li>
              <li>
                <b style={{ color: '#a4c627' }}>Regulation:</b> Supporting the alignment of regulatory frameworks to
                promote innovation and industry growth
              </li>
              <li>
                <b style={{ color: '#a4c627' }}>Production:</b> Strengthening production capabilities, with a focus on
                advanced technologies and sustainable practices
              </li>
              <li>
                <b style={{ color: '#a4c627' }}>Business Model & Long-Term Strategy:</b> Defining the Centre’s
                operational and business model to ensure long-term sustainability and regional leadership
              </li>
            </ol>
          </Typography>
        </Grid>

        <hr
          style={{
            margin: '40px 0',
            width: '100%',
            height: '0.5px',
            border: 'none',
            backgroundColor: '#333333',
          }}
        />
        <Grid item xs={12}>
          <Typography
            gutterBottom
            style={{
              margin: '30px 12px',
            }}
            color='primary'
            variant='h2'
            align='center'>
            Agenda Overview
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          spacing={12}
          style={{
            marginBottom: 20,
          }}>
          <Typography
            gutterBottom
            style={{
              padding: '20px',
              border: '1px solid #a4c627',
              borderRadius: '20px',
              marginRight: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
            color='primary'
            variant='h3'
            align='center'>
            Day 1
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          style={{
            marginBottom: 20,
          }}>
          <Typography
            gutterBottom
            style={{
              padding: '16px',
              border: '1px solid #a4c627',
              borderRadius: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
            color='textSecondary'
            variant='h5'
            align='left'>
            Opening remarks, presentations on the Abu Dhabi Life Science Hub, and in-depth Working Group sessions
            focused on the above areas.
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          style={{
            marginBottom: 20,
          }}>
          <Typography
            gutterBottom
            style={{
              padding: '20px',
              border: '1px solid #a4c627',
              borderRadius: '20px',
              marginRight: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
            color='primary'
            variant='h3'
            align='center'>
            Day 2
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          style={{
            marginBottom: 20,
          }}>
          <Typography
            gutterBottom
            style={{
              padding: '16px',
              border: '1px solid #a4c627',
              borderRadius: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
            color='textSecondary'
            variant='h5'
            align='left'>
            Presentation of findings from the working groups, followed by a Plenary Discussion and Recommendations for
            the Centre’s operating model and strategy.
          </Typography>
        </Grid>

        <hr
          style={{
            margin: '40px 0',
            width: '100%',
            height: '0.5px',
            border: 'none',
            backgroundColor: '#333333',
          }}
        />

        <Grid
          item
          xs={12}
          style={{
            marginBottom: 20,
          }}>
          <Typography
            gutterBottom
            style={{
              margin: '30px 12px',
            }}
            color='primary'
            variant='h2'
            align='center'>
            Event Details
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          style={{
            marginBottom: 20,
          }}>
          <Typography
            gutterBottom
            style={{
              padding: '20px 12px',
              border: '1px solid #a4c627',
              borderRadius: '20px',
              marginRight: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
            color='primary'
            variant='h3'
            align='center'>
            Dates
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          style={{
            marginBottom: 20,
          }}>
          <Typography
            gutterBottom
            style={{
              padding: '16px 12px',
              border: '1px solid #a4c627',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
            color='textSecondary'
            variant='h5'
            align='left'>
            4-5 November, 2024 | 09:00 AM - 05:00 PM
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          style={{
            marginBottom: 20,
          }}>
          <Typography
            gutterBottom
            style={{
              padding: '20px',
              border: '1px solid #a4c627',
              borderRadius: '20px',
              marginRight: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
            color='primary'
            variant='h3'
            align='center'>
            Venue
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          style={{
            marginBottom: 20,
          }}>
          <Typography
            gutterBottom
            style={{
              padding: '16px',
              border: '1px solid #a4c627',
              borderRadius: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
            color='textSecondary'
            variant='h5'
            align='left'>
            Abu Dhabi Department of Health (DoH) Headquarters, Building 35 Kanadel Street, Al Rawda – Abu Dhabi
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          style={{
            marginBottom: 20,
          }}>
          <Typography
            gutterBottom
            style={{
              padding: '20px',
              border: '1px solid #a4c627',
              borderRadius: '20px',
              marginRight: '20px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
            color='primary'
            variant='h3'
            align='center'>
            Format
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          style={{
            marginBottom: 20,
          }}>
          <Typography
            gutterBottom
            style={{
              padding: '16px',
              border: '1px solid #a4c627',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
            color='textSecondary'
            variant='h5'
            align='left'>
            In-person event with four expert working groups
          </Typography>
        </Grid>
      </Grid>
      <GroupRegistrationFrom source={source} page='egm' />
    </Layout>
  );
};

Register.propTypes = {
  search: PropTypes.object,
};
export default withLocation(Register);
