export default {
  formId: 'registrationForm',
  formField: {
    salutation: {
      name: 'salutation',
      label: 'Salutation',
      requiredErrorMsg: 'salutation is required',
    },
    firstName: {
      name: 'firstName',
      label: 'First Name',
      requiredErrorMsg: 'First name is required',
    },
    lastName: {
      name: 'lastName',
      label: 'Last Name',
      requiredErrorMsg: 'Last name is required',
    },
    email: {
      name: 'email',
      label: 'Email',
      requiredErrorMsg: 'Email is required',
      invalidErrorMsg: 'Invalid email',
    },
    confirmedEmail: {
      name: 'confirmedEmail',
      label: 'Confirm Your Email',
      requiredErrorMsg: 'Email is required',
      invalidErrorMsg: 'Invalid email',
    },
    country: {
      name: 'country',
      label: 'Country of Residence',
      requiredErrorMsg: 'Country of residence is required',
    },
    nationality: {
      name: 'nationality',
      label: 'Nationality',
      requiredErrorMsg: 'Nationality is required',
    },

    countryCode: {
      name: 'countryCode',
      label: 'Code',
      requiredErrorMsg: 'Country code is required',
    },
    mobile: {
      name: 'mobile',
      label: 'Mobile Number',
      requiredErrorMsg: 'Mobile number is required',
      invalidErrorMsg: 'Invalid phone number',
    },
    jobTitle: {
      name: 'jobTitle',
      label: 'Job Title',
      requiredErrorMsg: 'Job title is required',
    },
    jobRole: {
      name: 'jobRole',
      label: 'Job Role',
      requiredErrorMsg: 'Job role is required',
    },

    jobRoleSpecify: {
      name: 'jobRoleSpecify',
      label: 'Other job role , please specify',
      requiredErrorMsg: 'Specifying what other in job role is required',
    },
    company: {
      name: 'company',
      label: 'Company Name',
      requiredErrorMsg: 'Company name is required',
    },
    industrySector: {
      name: 'industrySector',
      label: 'Industry Sector',
      requiredErrorMsg: 'Industry sector is required',
    },
    industrySectorSpecify: {
      name: 'industrySectorSpecify',
      label: 'Other industry sector , please specify',
      requiredErrorMsg: 'Specifying what other in industry sector is required',
    },
    events: {
      name: 'events',
      label: 'I would like to register to attend #GMIS Connect China Webinar from November 27, 2023',
      requiredErrorMsg: 'Selecting Events is required',
    },
    // accreditedMedia: {
    //   name: 'accreditedMedia',
    //   label: 'Tick if you wish to register as accredited media',
    // },
    recieveMoreInfo: {
      name: 'recieveMoreInfo',
      label: 'Sign me up for the newsletter',
    },
    conditions: {
      name: 'conditions',
      label: 'I agree to the Terms and Conditions *',
      requiredErrorMsg: 'Agreement to the terms and conditions is required',
    },
    coordination: {
      name: 'coordination',
      label: 'Coordination',
    },
    regulation: {
      name: 'regulation',
      label: 'Regulation',
    },
    production: {
      name: 'production',
      label: 'Production',
    },
    bmAndLTStrategy: {
      name: 'bmAndLTStrategy',
      label: 'Business Model & Long-Term Strategy',
    },
  },
};
