import React, { useState } from 'react';
import { useField } from 'formik';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, Typography, Box } from '@material-ui/core';
import CustomLink from '../../custom-link/index';

export default function CheckboxField(props) {
  const { label, name } = props;
  const [field, meta] = useField(props);

  const [state, setState] = useState({
    // accreditedMedia: field.value.accreditedMedia,
    recieveMoreInfo: field.value.recieveMoreInfo,
    conditions: field.value.conditions,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  return (
    <Box clone width={1} p={2}>
      <FormControl error={meta.touched && meta.error ? true : false}>
        <FormControlLabel
          value={field.value}
          name={name}
          checked={field.value}
          control={<Checkbox onChange={handleChange} {...field} />}
          label={
            props.terms ? (
              <Typography variant='body2'>
                I agree to the <span> </span>
                <CustomLink linkText='Terms and Conditions *' external type='2' linkUrl='/terms-and-conditions' />
              </Typography>
            ) : (
              <Typography variant='body2'>{label}</Typography>
            )
          }
        />
        <FormHelperText>{meta.touched && meta.error ? meta.error : ''}</FormHelperText>
      </FormControl>
    </Box>
  );
}
