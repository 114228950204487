import { Box, Button, CircularProgress, Grid, Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import axios from 'axios';
import { Form, Formik } from 'formik';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import ImageContainer from '../image-container';
import SectionContainerLayout from '../section-container-layout/section-container-layout';
import checkoutFormModel from './formModel/checkout-form-model';
import formInitialValues from './formModel/form-initial-values';
import validationSchema from './formModel/validation-schema';
import OccupationDetailsForm from './steppers/occupation-details-form';
import PersonalDetailsForm from './steppers/personal-details-form';
import SupscriptionsForm from './steppers/supscriptions-form';
const { formId, formField } = checkoutFormModel;
const steps = ['Personal Information', 'Company Information', 'Review & Submit'];

function getStepContent(step, values) {
  switch (step) {
    case 0:
      return <PersonalDetailsForm allValues={values} formField={formField} />;
    case 1:
      return <OccupationDetailsForm formField={formField} />;
    case 2:
      return <SupscriptionsForm formField={formField} />;
    default:
      return <div>Not Found</div>;
  }
}
export default function RegistrationFrom(props) {
  const [activeStep, setActiveStep] = useState(0);
  const currentValidationSchema = validationSchema[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  async function submitForm(values, actions) {
    // setActiveStep(activeStep + 1);
    // console.log('result', JSON.stringify(values, null, 2));

    // const axiosPost = {
    //   salutation: values.salutation.name,
    //   firstName: values.firstName,
    //   lastName: values.lastName,
    //   email: values.email,
    //   country: values.country.name,
    //   nationality: values.nationality.name,
    //   countryCode: values.country.dialCode,
    //   mobile: values.mobile,
    //   jobTitle: values.jobTitle,
    //   jobRole:
    //     values.jobRole.name === 'Other' && values.jobRoleSpecify !== '' ? values.jobRoleSpecify : values.jobRole.name,
    //   company: values.company,
    //   industrySector: values.industrySector.name,
    //   // events: values.events,
    //   events: ['GMIS Connect China Webinar'],
    //   industrySector:
    //     values.industrySector.name === 'Other' && values.industrySectorSpecify !== ''
    //       ? values.industrySectorSpecify
    //       : values.industrySector.name,
    //   isMoreInfo: values.recieveMoreInfo,
    //   isTermsAndConditions: values.conditions,
    //   source: props.source,
    // };
    // console.log('axiosPost', axiosPost);
    // return;
    try {
      const REGData = {
        categoryCode: 'GUEWEBSIT',
        salutation: values.salutation.name,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        contact: values.mobile,
        countryCode: values.country.dialCode,
        country: values.country.name,
        nationality: values.nationality.name,
        jobTitle: values.jobTitle,
        jobRole:
          values.jobRole.name === 'Other' && values.jobRoleSpecify !== '' ? values.jobRoleSpecify : values.jobRole.name,
        companyName: values.company,
        industrySector:
          values.industrySector.name === 'Other' && values.industrySectorSpecify !== ''
            ? values.industrySectorSpecify
            : values.industrySector.name,
        gmisEvent: 1,
        // events: values.events,
        events: ['GMIS Experts Group Meeting'],
        moreInfo: values.recieveMoreInfo,
        fromWebsite: true,
        tag: props.source,
        isInfosalon: true,
        rankings: {
          coordination: values.coordination,
          regulation: values.regulation,
          production: values.production,
          businessModelAndLongTermStrategy: values.bmAndLTStrategy,
        },
      };

      const response = await axios.post('https://gmisummit.com/email-api', REGData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      ///////////////////////////////////
      // let sourceArray = [];
      // sourceArray['Website'] = 'A01';
      // sourceArray['Instagram'] = 'A02';
      // sourceArray['Dubai Economy'] = 'A03';
      // sourceArray['Dubai Industrial City'] = 'A04';
      // sourceArray['JAFZA'] = 'A05';
      // sourceArray['LinkedIn'] = 'A06';
      // sourceArray['MOIAT'] = 'A07';
      // sourceArray['Dubai Chamber'] = 'A08';
      // sourceArray['facebook'] = 'A09';
      // sourceArray['Gdn'] = 'A10';
      // sourceArray['Gs'] = 'A11';
      // sourceArray['Ns'] = 'A12';
      // sourceArray['Youtube'] = 'A13';
      // sourceArray['Twitter'] = 'A14';

      // let jobRoleArray = [];
      // jobRoleArray['Owner/Chairman/President'] = 'B01';
      // jobRoleArray['Manager Director / General Manager'] = 'B02';
      // jobRoleArray['C-Level(CEO, COO, CFO, etc)'] = 'B03';
      // jobRoleArray['Director'] = 'B04';
      // jobRoleArray['Department Head / Senior Manager'] = 'B05';
      // jobRoleArray['Student'] = 'B06';
      // jobRoleArray['Junior Manager/Executive'] = 'B07';
      // jobRoleArray['Admin/Coordinate/Secretary/PA'] = 'B08';
      // jobRoleArray['Professor/University Employee'] = 'B09';
      // jobRoleArray['Other'] = 'B99';

      // let industrialSectorArray = [];
      // industrialSectorArray['Agriculture'] = 'C01';
      // industrialSectorArray['Education'] = 'C02';
      // industrialSectorArray['Academia'] = 'C03';
      // industrialSectorArray['Food and Beverage'] = 'C04';
      // industrialSectorArray['Consultant'] = 'C05';
      // industrialSectorArray['Construction'] = 'C06';
      // industrialSectorArray['Additive Manufacturing'] = 'C07';
      // industrialSectorArray['Engineering'] = 'C08';
      // industrialSectorArray['Computer Technology'] = 'C09';
      // industrialSectorArray['Healthcare'] = 'C10';
      // industrialSectorArray['Marketing'] = 'C11';
      // industrialSectorArray['Energy & Utilities'] = 'C12';
      // industrialSectorArray['Research and Development'] = 'C13';
      // industrialSectorArray['Finance'] = 'C14';
      // industrialSectorArray['Transportation and logistics'] = 'C15';
      // industrialSectorArray['Artificial Intelligence Technologies'] = 'C16';
      // industrialSectorArray['Automotive'] = 'C17';
      // industrialSectorArray['Textiles'] = 'C18';
      // industrialSectorArray['Media'] = 'C19';
      // industrialSectorArray['Electronics'] = 'C20';
      // industrialSectorArray['Telecommunications'] = 'C21';
      // industrialSectorArray['Oil & Gas'] = 'C22';
      // industrialSectorArray['Chemicals'] = 'C23';
      // industrialSectorArray['Automation'] = 'C24';
      // industrialSectorArray['Digitalisation'] = 'C25';
      // industrialSectorArray['Pharmaceuticals'] = 'C26';
      // industrialSectorArray['Mining'] = 'C27';
      // industrialSectorArray['Steel'] = 'C28';
      // industrialSectorArray['Biotechnology and life sciences'] = 'C29';
      // industrialSectorArray['Aerospace'] = 'C30';
      // industrialSectorArray['Future of Work'] = 'C31';
      // industrialSectorArray['Plastic'] = 'C32';
      // industrialSectorArray['Big Data'] = 'C33';
      // industrialSectorArray['Cybersecurity'] = 'C34';
      // industrialSectorArray['Defence Internet of Things (IoT)'] = 'C35';
      // industrialSectorArray['Aviation'] = 'C36';
      // industrialSectorArray['Blockchain'] = 'C37';
      // industrialSectorArray['Pulp and paper'] = 'C38';
      // industrialSectorArray['Robotics Technology'] = 'C39';
      // industrialSectorArray['Petrochemicals'] = 'C40';
      // industrialSectorArray['Other'] = 'C99';

      // const infoSalonsAuth = {
      //   email: 'shahbaz@eyesomex.com',
      //   password: '4EdGx]XZ@',
      // };

      // if (!jobRoleArray[values.jobRole.name]) {
      //   jobRoleArray[values.jobRole.name] = jobRoleArray['Other'];
      // }
      // if (!industrialSectorArray[values.industrySector.name]) {
      //   industrialSectorArray[values.industrySector.name] = industrialSectorArray['Other'];
      // }

      // const infoSalonsPayload = {
      //   Salutation: values.salutation.name,
      //   FirstName: values.firstName,
      //   LastName: values.lastName,
      //   Company: values.company,
      //   Position: values.jobTitle,
      //   Country: values.country.name,
      //   PhoneCountry: values.country.dialCode.substring(1),
      //   Phone: values.mobile,
      //   MobileCountry: values.country.dialCode.substring(1),
      //   Mobile: values.mobile,
      //   Email: values.email,
      //   ResponseCodes: [
      //     { Key: sourceArray[props.source], Value: props.source },
      //     { Key: jobRoleArray[values.jobRole.name], Value: values.jobRole.name },
      //     {
      //       Key: industrialSectorArray[values.industrySector.name],
      //       Value: values.industrySector.name,
      //     },
      //   ],
      // };

      // const infoSalonToken = await axios
      //   .post('https://apiservices.infosalons.ae/API/ManagementService/api/account/login', infoSalonsAuth)
      //   .then((response) => {
      //     if (response.data.data.tokenInfo.authToken) {
      //       axios
      //         .post(
      //           'https://apiservices.infosalons.ae/API/ManagementService/api/ClientApi/InsertRego',
      //           infoSalonsPayload,
      //           {
      //             headers: {
      //               'Content-Type': 'application/json',
      //               Authorization: 'Bearer ' + response.data.data.tokenInfo.authToken,
      //               ShowApiId: '6cb9b216-9f35-4572-cb84-08d96b0362d2',
      //             },
      //           }
      //         )
      //         .then((_data) => {
      //           console.log(_data);
      //         })
      //         .catch((err) => {
      //           console.log('error', err);
      //         });
      //     }
      //   })
      //   .catch((err) => {
      //     console.log('error', err);
      //   });
      //////////////////////////////////////

      // const response = await axios.post(process.env.GATSBY_API_URL + 'register', axiosPost);
      actions.setSubmitting(false);

      if (response.status === 200) {
        switch (props.page) {
          case 'main':
            navigate('/registration/thankyou');
            break;
          default:
            navigate(`/registration/${props.page}/thankyou`);
            break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  const handleFormikSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      if (activeStep === 0) {
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
        // axios
        //   .post(process.env.GATSBY_EMS_API_URL + 'guests/email-exist', {
        //     email: values.email,
        //   })
        //   .then((response) => {
        //     if (response.status === 200) {
        //       Swal.fire({
        //         icon: 'error',
        //         title: 'Oops...',
        //         text: 'Email already exist',
        //       });
        //       actions.setSubmitting(false);
        //     }
        //   })
        //   .catch((err) => {
        //     setActiveStep(activeStep + 1);
        //     actions.setTouched({});
        //     actions.setSubmitting(false);
        //     // console.log('error', err);
        //   });
      } else {
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      }
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <SectionContainerLayout>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            gutterBottom
            style={{
              margin: '30px 12px',
            }}
            color='primary'
            variant='h2'
            align='center'>
            Registration Form
          </Typography>
        </Grid>
        {props.imageName && (
          <Grid item xs={12} sm={6}>
            <Fade bottom>
              <Box>
                <ImageContainer filename={props.imageName} altText={props.imageAlt || '#GMIS2021'} />
              </Box>
            </Fade>
          </Grid>
        )}
        <Grid item container xs={12} sm={props.imageName ? 6 : 12}>
          <Box width={1}>
            <Box marginLeft='auto' marginRight='auto' width={{ xs: '100%', md: '50%' }} my={7}>
              <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>
                      <Typography variant='caption'>{label}</Typography>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            {/*            <Box width={1} display='flex' alignItems='center' justifyContent='center'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => window.open('/pdfs/GMIS_Trieste 2023_Conference_Programme.pdf')}>
                  Download Agenda
                </Button>
            </Box>  */}
          </Box>
          <Box width={1}>
            <React.Fragment>
              <Formik
                initialValues={formInitialValues}
                onSubmit={handleFormikSubmit}
                validationSchema={currentValidationSchema}>
                {({ isSubmitting, values }) => {
                  return (
                    <Form id={formId} autoComplete='off'>
                      {getStepContent(activeStep, values)}
                      <Box mt={5}>
                        <Grid container justify='space-between'>
                          <Box order={{ sm: 2, md: 1 }} clone>
                            <Grid item xs={12} md={1} lg={1}>
                              <Box mt={1}>
                                {activeStep !== 0 && (
                                  <Button fullWidth variant='text' onClick={handleBack}>
                                    Back
                                  </Button>
                                )}
                              </Box>
                            </Grid>
                          </Box>
                          <Box order={{ sm: 1, md: 2 }} clone>
                            <Grid item xs={12} md={1} lg={1}>
                              <Box mt={1}>
                                <Button
                                  disabled={isSubmitting}
                                  fullWidth
                                  type='submit'
                                  variant='contained'
                                  color='primary'>
                                  {isLastStep ? 'Submit' : 'Next'}
                                </Button>
                                {isSubmitting && <CircularProgress size={24} />}
                              </Box>
                            </Grid>
                          </Box>
                        </Grid>
                      </Box>
                    </Form>
                  );
                }}
              </Formik>
            </React.Fragment>
          </Box>
        </Grid>
      </Grid>
    </SectionContainerLayout>
  );
}
